import './Upload.scss';
import { useState } from 'react';

import countries from '../../data/countries.json';

const Upload = ({ onChange, onUpload, loading }) => {
    const [select, setSelect] = useState('');
    const [files, setFiles] = useState(0);
    const [images, setImages] = useState(0);

    const handleChange = (e) => {
        setFiles(e.target.files.length);
        setImages(e.target.files);
        if (onChange) onChange(e);
    };

    return (
        <div className="upload">
            <div className="body">
                <label htmlFor="select">Select country</label>
                <select name="select" onChange={(e) => setSelect(e.target.value)} value={select} id="select">
                    {countries.map((c) => (
                        <option key={c.code} value={c.name}>
                            {c.name}
                        </option>
                    ))}
                </select>
                <h3>Drag and Drop up to 15 images</h3>
                <p>Each image may take up to 60 seconds to process depending on image complexity and will be delivered in one batch</p>

                <div className="drag">
                    {files > 0 ? (
                        `${files} files selected`
                    ) : (
                        <span>
                            Drop file here <br />
                            or click to upload
                        </span>
                    )}
                    <input type="file" name="images[]" onChange={handleChange} multiple />
                </div>
            </div>
            <div className="footer">
                <button onClick={() => onUpload({ images, select })} className="btn">
                    {loading ? 'Uplading...' : 'Upload'}
                </button>
            </div>
        </div>
    );
};

export default Upload;
