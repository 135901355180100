import './Results.scss';
import { useState } from 'react';

const imgUrl = process.env.APP_URL || 'http://localhost:5300';

const Results = ({ data, onNew }) => {
    const [img, setImg] = useState(null);
    return (
        <div className="results">
            <button className="btn" onClick={onNew}>
                Upload new batch
            </button>
            <h3>Results - {data.images.length} Records</h3>

            <div className="body">
                {data &&
                    data.images.length > 0 &&
                    data.images.map((img) => (
                        <div
                            key={img.id}
                            className={'item ' + (img.result ? 'pass' : 'fake')}
                            style={{
                                backgroundImage: 'url(' + imgUrl + img.url + ')',
                            }}
                            onClick={() => setImg(imgUrl + img.url)}
                        >
                            <div className="filename">{img.filename}</div>
                        </div>
                    ))}
            </div>
            {img && (
                <div className="img-preview" data-bg onClick={(e) => e.target.getAttribute('data-bg') && setImg(null)}>
                    <div className="img-body">
                        <img src={img} alt="" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Results;
