import logo from './logo.svg';
import './App.scss';
import Upload from './containers/upload/Upload';
import { Component } from 'react';
import Loading from './containers/loading/Loading';
import APIs from './services/Apis';
import Results from './containers/results/Results';

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            estimatedTime: 0,
            loading: false,
            uploading: false,
            step: 0,
            data: null,
        };

        this.api = new APIs();

        this.handleUpload = this.handleUpload.bind(this);
    }

    handleUpload({ images, select }) {
        if (!images.length) {
            return;
        }

        this.setState({ uploading: true });

        this.api.upload(images, select).then(({ data }) => {
            this.setState({ loading: true, uploading: false, estimatedTime: images.length });
            this.api.result(data.id).then(({ data }) => {
                this.setState({ loading: false, step: 1, data });
            });
        });
    }

    render() {
        const { estimatedTime, loading, uploading, step, data } = this.state;

        return (
            <div className="app">
                <div className="logo">
                    <img src="/logo.svg" />
                </div>
                <div className="app-body">
                    {!step && !loading && <Upload onUpload={this.handleUpload} loading={uploading} />}
                    {loading && <Loading time={estimatedTime} />}
                    {step > 0 && !loading && data && <Results onNew={() => this.setState({ step: 0 })} data={data} />}
                </div>
            </div>
        );
    }
}

export default App;
