import axios from 'axios';

export default class APIs {
    constructor(transId) {
        this.apiUrl = process.env.API_URL || 'http://localhost:5300/api/transaction';
        this.http = axios.create({
            baseURL: this.apiUrl,
            headers: { 'X-Token-Id': transId, 'Content-Type': 'application/json' },
        });
    }

    /**
     * Make a request to verify the transaction id.
     * @param {String} token
     */
    result(id) {
        return new Promise((resolve, reject) => {
            this.http
                .get('/results/' + id)
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => this.handleError(e, reject));
        });
    }

    /**
     * Make a request to verify the transaction id.
     * @param {String} token
     */
    upload(images, country) {
        const data = new FormData();
        for (const img of images) {
            data.append('images[]', img);
        }

        return new Promise((resolve, reject) => {
            this.http
                .post('/create?country=' + country, data)
                .then((res) => {
                    resolve(res);
                })
                .catch((e) => this.handleError(e, reject));
        });
    }

    /**
     * Handles errors.
     * @param {Object} error
     */
    handleError(error, reject) {
        reject(error);
    }
}
