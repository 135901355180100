import './Loading.scss';
import { useState } from 'react';

import countries from '../../data/countries.json';

const Loading = ({ time }) => {
    return (
        <div className="loading">
            <img src="/Rolling-1s-200px.gif" alt="" />
            <h2>Processing Records</h2>
            <p>
                Each image may take up to 60 seconds to process depending on image complexity
                <br /> and will be delivered in one batch
            </p>

            <h3>Estimated Time: {time} min</h3>
            <h3>Page will refresh by itself. Please do not close</h3>
        </div>
    );
};

export default Loading;
